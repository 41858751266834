import { useEffect, useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { CardModal, HeightTransitioner } from '@/components/common'
import LoginOptions from './LoginOptions'
import Signup from './Signup'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import useAuth from '@/services/hooks/useAuth'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import Image from '@/components/contentful/Image'
import { AngelFundingLogo } from '@/components/svg'
import ABTester, { ABTestProps } from '@/components/ABTester'
import { Text } from '../common'
import VerifyAccountForm from './VerifyAccountForm'
import classNames from 'classnames'

export const titleMap = {
  register: 'Sign up',
  login: 'Log in',
  resetPassword: 'Reset password',
  verifyAccount: 'Get started',
  verifyEmail: 'Email verification',
}

interface Props {
  showTitle?: boolean
}

export const AuthModal: React.FC<Props> = ({ showTitle = false }) => {
  const [email, setEmail] = useState('')
  const { modalIsOpen, closeModal, modalView, setModalView } = useAuth()
  const router = useRouter()
  const siteAssets = useSiteAssets()

  useEffect(() => {
    if (modalIsOpen && modalView) {
      SegmentHandler.track('Auth Started', {
        location: router.asPath,
        value: modalView,
      })
    }
  }, [modalIsOpen, modalView, router.asPath])

  const setIsOpen = useCallback(() => {
    // Don't have a good way to track resetPassword cancellation because
    // the modal stays open after a successful reset.
    if (modalView !== 'resetPassword') {
      SegmentHandler.track('Auth Cancelled', {
        location: router.asPath,
        value: modalView,
      })
    }

    closeModal()
  }, [modalView, router.asPath, closeModal])

  return (
    <CardModal isOpen={modalIsOpen} setIsOpen={setIsOpen} width="w-[360px]">
      <HeightTransitioner>
        <div className="w-full px-8 pt-8 pb-6">
          <div
            className={classNames({ 'mb-12': !showTitle, 'mb-6': showTitle })}
          >
            {siteAssets && siteAssets.logo && (
              <Image
                image={siteAssets.logo}
                width={160}
                className="w-40 mx-auto flex-initial"
              />
            )}
            {siteAssets && !siteAssets.logo && (
              <AngelFundingLogo
                fill="#000"
                className="w-40 mx-auto flex-initial"
              />
            )}
          </div>
          {showTitle && (
            <Text
              as="h2"
              preset="heading.lg"
              className={classNames('font-light mb-4', {
                'text-center': modalView === 'verifyEmail',
              })}
            >
              {titleMap[modalView]}
            </Text>
          )}

          {modalView === 'verifyAccount' && (
            <VerifyAccountForm
              email={email}
              showTitle={showTitle}
              setEmail={setEmail}
              setView={setModalView}
            />
          )}
          {(modalView === 'register' || modalView === 'verifyEmail') && (
            <Signup
              email={email}
              setEmail={setEmail}
              view={modalView}
              setView={setModalView}
            />
          )}
          {(modalView === 'login' || modalView === 'resetPassword') && (
            <LoginOptions
              email={email}
              setEmail={setEmail}
              view={modalView}
              setView={setModalView}
            />
          )}
        </div>
      </HeightTransitioner>
    </CardModal>
  )
}

const ABAuthModal: React.FC<Props> = (props) => {
  return (
    <ABTester name="auth_modal_titles">
      {({ decision }: ABTestProps) => (
        <AuthModal {...props} showTitle={decision.variationKey === 'on'} />
      )}
    </ABTester>
  )
}

export default ABAuthModal
