import React, { ChangeEvent, useCallback, useState } from 'react'

import AuthInput from './AuthInput'
import { Button, ButtonBase, ErrorBox, Text } from '../common'
import { AuthModalView } from '../context/AuthContext/AuthContext'
import { formUtil, report } from '@/utils'
import user from '@/services/api/user'
import { NotifiableError } from '@bugsnag/js'
import classNames from 'classnames'

export interface Props {
  email: string
  setEmail: (email: string) => void
  setView: (view: AuthModalView) => void
  showTitle?: boolean
}

const VerifyAccountForm: React.FC<Props> = ({
  email,
  setEmail,
  setView,
  showTitle,
}) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()

      if (!formUtil.validateEmail(email)) {
        setError('Please enter a valid email address')
        setLoading(false)
        return
      }

      setError('')
      setLoading(true)

      try {
        const accountExists = await user.verifyAccountExists(email)

        // send to login if it does
        if (accountExists) {
          setView('login')
        } else {
          setView('register')
        }
      } catch (error: unknown) {
        report.error(error as NotifiableError)
        setError('An error occurred. Please try again later.')
      }
      setLoading(false)
    },
    [email, setView]
  )

  return (
    <>
      <Text
        as="p"
        preset="body.md"
        className={classNames('mb-4', {
          '-mt-2': showTitle,
          '-mt-6': !showTitle,
        })}
      >
        Start by entering your email address to sign in or create your Angel
        account.
      </Text>
      <form className="" onSubmit={handleSubmit}>
        <ErrorBox errorText={error} className="mb-4" />
        <AuthInput
          name="email"
          type="text"
          placeholder="EMAIL"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          value={email}
        />
        <Button
          disabled={loading}
          className="w-full rounded-full flex items-center justify-center mt-4"
        >
          <span>Continue</span>
        </Button>
      </form>

      <div className="flex flex-col w-full items-center mt-6">
        <Text as="h4" preset="heading.sm">
          Already a member?
        </Text>
        <ButtonBase
          className="text-primary text-sm uppercase"
          onClick={() => setView('login')}
          disabled={loading}
        >
          Login
        </ButtonBase>
      </div>
    </>
  )
}

export default VerifyAccountForm
