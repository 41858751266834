import { useMemo } from 'react'
import { useDecision, OptimizelyDecision } from '@optimizely/react-sdk'
import { getNextRouterQueryParam } from '@/utils'

const useABTest = (name: string): [OptimizelyDecision, boolean] => {
  const [decision, clientReady] = useDecision(name)

  const overrideVariationKey = useMemo(() => {
    const asPath = typeof window === 'undefined' ? '' : window.location.search
    return getNextRouterQueryParam(`flag_${name}`, { asPath, query: {} })
  }, [name])

  return [
    {
      ...decision,
      variationKey: overrideVariationKey || decision.variationKey,
    },
    clientReady,
  ]
}

export default useABTest
